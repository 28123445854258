<template>
  <div class="line-ending">
    <span class="gg-games"></span>
  </div>
</template>

<style scoped lang="scss">
.line-ending {
  text-align: center;
  margin-top: 2rem;

  .gg-games {
    margin: 0 auto;
    --ggs: 2;
  }
}
</style>