import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a295be0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "detail" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DetailHeader = _resolveComponent("DetailHeader")!
  const _component_Metadata = _resolveComponent("Metadata")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_List = _resolveComponent("List")!
  const _component_Carousel = _resolveComponent("Carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_DetailHeader, { item: _ctx.contentItem }, null, 8, ["item"]),
          _createVNode(_component_Metadata, {
            class: "metadata",
            item: _ctx.contentItem
          }, null, 8, ["item"])
        ])),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.components, (component, key) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "component",
        key: key
      }, [
        (_ctx.isGrid(component) && component.items?.length)
          ? (_openBlock(), _createBlock(_component_Grid, {
              key: 0,
              "component-data": component
            }, null, 8, ["component-data"]))
          : (_ctx.isList(component) && component.items?.length)
            ? (_openBlock(), _createBlock(_component_List, {
                key: 1,
                "component-data": component
              }, null, 8, ["component-data"]))
            : (_ctx.isCarousel(component) && component.items?.length)
              ? (_openBlock(), _createBlock(_component_Carousel, {
                  key: 2,
                  "component-data": component
                }, null, 8, ["component-data"]))
              : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}