
import {defineComponent} from "vue";
import ContentItem from "@/api/models/contentitem";

export default defineComponent({

  props: {
    item: ContentItem
  }
})
