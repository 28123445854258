
import {defineComponent} from "vue";
import ContentItem from "../../api/models/contentitem";
import Link from "@/components/browse/Link.vue";
import LineEnding from "@/components/LineEnding.vue";

export default defineComponent({
  components: {LineEnding, Link},
  props: {
    item: {
      type: ContentItem
    },
    descriptionText: {
      type: String,
      default: 'About'
    }
  }
})
