import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b6b5492"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.link,
    target: "_blank",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.log && _ctx.log(...args))),
    class: "link font-lato-regular text-sm md:text-base lg:text-lg"
  }, _toDisplayString(_ctx.text), 9, _hoisted_1))
}