
import {defineComponent} from "vue";

export default defineComponent({
	props: {
		link: String,
		text: String
	},
	methods: {
		log: function () {
			this.$gtag.event('link_clicked', {
				event_category: this.text,
				event_label: this.link
			})
		}
	}
})
