
import {defineComponent} from "vue";
import DetailHeader from '@/components/detail/Header.vue';
import ContentItem from "@/api/models/contentitem";
import Metadata from "@/components/detail/Metadata.vue";
import Component from "@/api/models/component";
import Carousel from "@/components/browse/Carousel.vue";
import Grid from "@/components/browse/Grid.vue";
import List from "@/components/browse/List.vue";

export default defineComponent({
  name: 'Detail',
  components: {
    Metadata,
    DetailHeader,
    Carousel,
    Grid,
    List
  },
  props: {
    item: {
      type: ContentItem
    }
  },
  methods: {
    setMeta: function () {
        const meta = {
          title: `${this.contentItem.title} | EscapeGaming.io - Your home for escape room & puzzle games`,
          description: `${this.contentItem.title} - ${this.contentItem.description} | EscapeGaming.io`,
          image: this.contentItem.images.tile,
          video: this.contentItem?.videos?.trailer || this.contentItem?.videos?.walkthrough || ''
        }

        this.$global.updateMeta(meta);
    },
    getRelated: function () {
      return this.$api.getRelated(this.contentItem)
        .catch(() => {}); // Ignore
    },
    getSuggestions: function () {
      return this.$api.getSuggestions()
        .catch(() => {}); // Ignore
    },
    getSeries: function (series: string) {
        return this.$api.getSeries(series)
          .catch(() => {}); // Ignore
    },
    getFromDeveloper: function (developer: string) {
      return this.$api.getFromDeveloper(developer)
        .catch(() => {})
    },
    isGrid: (component: Component) => {
      return component.type === 'grid';
    },
    isList: (component: Component) => {
      return component.type === 'list';
    },
    isCarousel: (component: Component) => {
      return component.type === 'carousel';
    }
  },
  data: function () {
    return {
      components: [] as Component[],
      isLoading: true,
      contentItem: {} as ContentItem
    }
  },
  activated () {
      if (!this.isLoading) {
        this.setMeta();
      }
  },
  created: function () {
    this.$api.getItem({
      slug: this.$route.params.slug
    }).then((ci: ContentItem) => {
      const promises = [];

      this.contentItem = ci;
      this.isLoading = false;

      this.setMeta();

      if (ci.series) {
        promises.push(this.getSeries(ci.series));
      }

      promises.push(this.getRelated());
      promises.push(this.getSuggestions());

      Promise.all<any>(promises)
      .then((r) => {
        r.forEach((a: Component) => a && this.components.push(a));
      })
      .then(() => {
        if (ci.developer) {
          this.getFromDeveloper(ci.developer)
            .then((comp: any) => {
              if (comp && comp.items.length) {
              this.components.push(comp);
            }
          });
        }
      })
    });
  }
});
