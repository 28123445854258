import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65ed2b52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "youtube" }
const _hoisted_2 = ["src", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("iframe", {
      src: _ctx.item.videos.embedded + '?autoplay=1',
      title: _ctx.item.title,
      frameborder: "0",
      allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
      allowfullscreen: ""
    }, null, 8, _hoisted_2)
  ]))
}