
import {defineComponent} from "vue";
import ContentItem from "@/api/models/contentitem";
import HeaderItem from "@/components/browse/HeaderItem.vue";
import Youtube from "@/components/Youtube.vue";

export default defineComponent({
  components: {
    Youtube,
    HeaderItem
  },
  props: {
    item: {
      default: {} as ContentItem
    }
  },
  data: function () {
    return {
      style: {
        backgroundImage: `url(${this.item?.images?.header})`
      },
      showPlayer: false
    }
  }
})
